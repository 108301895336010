import React, { useState, useContext, useEffect } from "react"
import styled from "@emotion/styled"

import Paper from "./Paper"
import Cta from "./Cta"
import LocationSelect from "./LocationSelect"
import { LocationContext } from "../contexts/LocationContext"

const Container = styled.section`
  max-width: 130rem;
  padding: 3rem 1.5rem;
  margin: 0 auto;
`

const Content = styled.div`
  display: grid;
  padding: 3rem 0;
  grid-template-columns: repeat(4, minmax(200px, 350px));
  justify-content: center;
  text-align: center;
  .paper {
    padding: 0 1.5rem;
    margin: 1.5rem 0;
    :not(:last-child) {
      border-right: 1px solid #cfcfcf;
    }
  }
  @media only screen and (max-width: 68.75em) {
    grid-template-columns: repeat(2, minmax(250px, 500px));
    .paper {
      :not(:last-child) {
        border-right: none;
      }
      :nth-of-type(odd) {
        border-right: 1px solid #cfcfcf;
      }
    }
  }
  @media only screen and (max-width: 37.5em) {
    grid-template-columns: 1fr;
    .paper {
      border-right: none !important;
    }
  }
`

const Primary = styled.h2`
  text-align: center;
  font-size: 3.5rem;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.3;
  color: #2196f3;
  background: linear-gradient(to right, #2196f3, #2dc7ff);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
`

const Subtitle = styled.h3`
  font-size: 1.9rem;
  font-weight: 400;
  text-align: center;
  padding: 1.5rem 10rem;
  @media only screen and (max-width: 52em) {
    padding: 1.5rem 0;
  }
`

const Price = styled.p`
  font-size: 3rem;
  padding: 1rem 0;
  font-weight: 700;
  color: #505050;
  span {
    font-weight: 400;
    font-size: 1.8rem;
  }
`

const ListWrapper = styled.div`
  padding: 1rem 0;
  display: flex;
  justify-content: center;
`

const List = styled.ul`
  text-align: left;
  list-style: none;
`

const Item = styled.li`
  color: #505050;
  padding: 0.5rem 0 0.5rem 2rem;
  position: relative;
  &::before {
    position: absolute;
    left: 0;
    content: "\u276F";
    color: #2196f3;
  }
  span {
    font-size: 1.2rem;
  }
`

const Space = styled.div`
  height: 1.5rem;
`

const SelectWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    margin-right: 1rem;
  }
`

const Prices = ({ data: { title, subtitle, data, pricingPerLocation } }) => {
  const { loc } = useContext(LocationContext)

  function getPricingPerLocation() {
    return pricingPerLocation.find(obj => obj.province == loc.province)
  }

  const [selectedPpl, setSelectedPpl] = useState(() => getPricingPerLocation())

  useEffect(() => {
    setSelectedPpl(getPricingPerLocation());
  }, [loc]);

  function renderPriceList() {
    if (selectedPpl) {
      let mdPricingList = selectedPpl.pricingList;
      let jsxPricingList = [];

      // The following handles instances where the number of prices and quantities do not match
      let smallestListLength = Math.min(...Object.values(mdPricingList).map((list) => list.length));

      for (let i = 0; i < smallestListLength; i++) {
        jsxPricingList.push(
          <React.Fragment>
            {mdPricingList.prices[i]}<span>({mdPricingList.quantities[i]})</span><br />
          </React.Fragment>
        )
      }

      return jsxPricingList;
    }

    return
  }

  function renderServiceFeeByLocation(dataListItem) {
    if (selectedPpl) {
      return dataListItem.replace('[serviceFee]', selectedPpl.serviceFee);
    }
    return "Retreiving Service Fee Information";
  }

  return (
    <Container>
      <Primary>{title}</Primary>
      <Subtitle>{subtitle}</Subtitle>
      <SelectWrapper>
        <div>Not your city?</div>
        <LocationSelect color="#505050" fontSize="2.5rem" />
      </SelectWrapper>
      <Content>
        {data &&
          data.map(
            ({ name, price, data, cta, image, url, learnUrl }, count) => (
              <Paper
                title={name}
                image={
                  image.relativePath ? `/img/${image.relativePath}` : image
                }
                key={count}
                cta={
                  <div>
                    <Cta to={learnUrl} variant>
                      Learn more
                    </Cta>
                    <Space />
                    <Cta href={url}>{cta}</Cta>
                  </div>
                }
              >
                <Price>
                  {renderPriceList()}
                </Price>
                <ListWrapper>
                  <List>
                    {data &&
                      data.map((item, count) => {
                        const text = item.split("|")
                        return (
                          <Item key={count}>
                            {text[0].includes("[serviceFee]") ? renderServiceFeeByLocation(text[0]) : text[0]}
                            <span>{text[1]}</span>
                          </Item>
                        )
                      })}
                  </List>
                </ListWrapper>
              </Paper>
            )
          )}
      </Content>
    </Container>
  )
}

export default Prices
